<template>
  <div class="tag-data">
		<div class="no-data" v-if="tabs.length == 0">
			<a-list :data-source="[]" />
			<a-button type="primary" @click="openTagLibraryCreateModal">新建标签库</a-button>
		</div>
		<template v-else>
			<div class="tag-main">
				<div class="md-tabs-wrap">
					<div class="tabs-inner">
							<div class="tabs-inner-box" ref="tabsInnerBox">
								<div class="tabs-list">
									<div class="tabs-item"
										:class="{act: tab.active}"
										:style="{width: tabWidth + 'px'}"
										v-for="(tab) in tabsShowArray" :key="tab.tagId"
										@click="selectTab(tab)" :title="tab.tagName"
									>
										<span class="tabs-title inaline">{{tab.tagName}}</span>
									</div>
								</div>
								
								<div class="dropdown-wrap" v-if="tabsHideArray.length > 0"
									@mousemove="dropdownShow = true"
									@mouseleave="dropdownShow = false"
								>
									<div class="ic-more-box" :class="{active: dropdownShow}">
										<a-icon class="ic-more" type="double-right" />
									</div>
									<div class="dropdown" v-show="dropdownShow">
										<div class="dropdown-inner">
											<div class="dropdown-tabs-item" :class="{act: tab.active}" v-for="(tab) in tabsHideArray" :key="tab.tagId"
												@click="selectTab(tab)" :title="tab.tagName"
											>
												<span class="dropdown-tabs-title inaline">{{tab.tagName}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="ic-add-box" @click="openTagLibraryCreateModal">
									<a-icon class="ic-add" type="plus" />
								</div>
							</div>
					</div>
				</div>
			
				<div class="search-box" v-clickoutside="clickOutSide">
					<a-input-search class="search-input" placeholder="请输入搜索内容" v-model="searchVal"
						@search="onSearch" @focus="onFocusSearch"
					/>
					<div class="search-result-box" v-show="resultBoxShow">
						<div class="no-result" v-if="searchLoading">
							loading...
						</div>
						<div class="no-result" v-else-if="searchList.length == 0">
							无数据
						</div>
						<div class="result" v-else>
							<div class="top">
								<span class="t1">名称</span>
								<span class="t2">编码</span>
								<span class="t3">路径</span>
							</div>
							<div class="line-wrap">
								<div class="line" @click="toTagPath(item)" v-for="item in searchList" :key="item.tagId">
									<span class="t1 inaline">{{item.tagName}}</span>
									<span class="t2 inaline">{{item.tagCode}}</span>
									<span class="t3 inaline">{{item.path}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<keep-alive>
					<component 
						:is="'tagStructuralContent'"
						:id="tagId" 
						:key="tagkey"
						:defalultSelectId="defalultSelectId"
						:selectTagPath="selectTagPath"
						:searchFlag="searchFlag"
						@update-tabs="updateTabs"
						@remove-tab="removeTab"
					></component>
				</keep-alive>
			</div>
		</template>
		
		<!-- 新增标签库 -->
		<a-modal
      v-model="tagLibraryCreateVisible"
      centered
      title="新增标签库"
      @ok="addTagLibrary"
      :maskClosable="false" 
      :destroyOnClose="true"
      :ok-button-props="{ props: { loading: this.addTagLibraryLoading } }"
    >
      <a-form-model :model="addTagLibraryForm" :rules="addTagLibraryRules" ref="addTagLibraryForm" 
        :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }"
      >
        <a-form-model-item label="标签库名称" prop="name">
          <a-input placeholder="请输入标签库名称" :maxLength="20" v-model="addTagLibraryForm.name" />
        </a-form-model-item>
        <a-form-model-item label="标签库编码" prop="code">
          <a-input placeholder="请输入标签库编码" :maxLength="20" v-model="addTagLibraryForm.code" />
					<span>注：标签库编码一旦生成不可修改</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

	</div>
</template>

<script>
import tagStructuralContent from './components/Content.vue'
import { listTag, saveTag, searchTag } from '@/api/tag'
import {validateTagName} from '@/utils/validate'
import { mapGetters } from 'vuex'
export default {
	name: 'tag-structural',
	components: {
		tagStructuralContent
	},
	computed: {
		...mapGetters(['adminId']),
	},
	data() {
		const validateName =  (rule, value, callback) => {
			// console.log(rule, value, callback)
			value = value.trim()
			if (value === '') {
					callback(new Error('请输入标签库名称'))
			} else if (!validateTagName(value)) {
					callback(new Error('名称由英文字母、数字、中文字符、下划线、中划线、点组成'))
			} else {
					callback()
			}
		}
		const validateCode =  (rule, value, callback) => {
			// console.log(rule, value, callback)
			value = value.trim()
			if (value === '') {
				callback(new Error('请输入标签库编码'))
			} else if (!validateTagName(value)) {
				callback(new Error('编码由英文字母、数字、中文字符、下划线、中划线、点组成'))
			} else {
				callback()
			}
		}
		return {
			tagId: '',
			tagkey: '',
			defalultSelectId: '',
			selectTagPath: [], // 选中标签的路径 
			searchFlag: '',  // 点击搜索标志

			// 新增标签库弹框
			tagLibraryCreateVisible: false,
			addTagLibraryLoading: false,
			addTagLibraryForm: {
				name: '',
				code: ''
			},
			addTagLibraryRules: {
				name: [{required: true, validator: validateName, trigger: 'blur'}],
				code: [{required: true, validator: validateCode, trigger: 'blur'}]
			},

			// 标签库列表
			tabs: [],
			tabsShowArray: [],
			tabsHideArray: [],
			tabWidth: 100,
			dropdownShow: false,

			// 搜索值
			searchVal: '',
			searchList: [],
			resultBoxShow: false,
			searchLoading: false,

			// first-render
			isFirstRender: true
		}
	},
	created() {
		this.listTag()
	},
	mounted() {
		
	},
	beforeDestroy() {

	},
	methods: {
		onResize() {
			let _this = this
			this.$nextTick(() => {
				_this.transferTabs()
				if (!this.isFirstRender) return
				this.isFirstRender = false
				window.addEventListener("resize", function() {
					// console.log('resize')
					_this.transferTabs()
				})
			})
		},
		transferTabs() {
			// console.dir(this.$refs['tagTabsList'])
			let pNode = this.$refs['tabsInnerBox']
			if (pNode) {
				let sumW = pNode.clientWidth - 340
				let itemW = 106  // 100 + 6  120为单个宽度  6为外边距
				// console.log(sumW, itemW)
				let showNum = Math.floor(sumW / itemW)
				this.tabWidth = Math.floor(sumW / showNum - 6)
				let beforeArray = this.tabs.slice(0,showNum)
				let afterArray = this.tabs.slice(showNum)
				let activeIndex = afterArray.findIndex(item => item.active)
				if (activeIndex>=0) {
					let actItem = afterArray[activeIndex]
					let popItem = beforeArray.pop()
					afterArray.splice(activeIndex, 1)
					beforeArray.push(actItem)
					afterArray.unshift(popItem)
				}
				this.tabsShowArray = [...beforeArray]
				this.tabsHideArray = [...afterArray]
			}
		},
		// 标签列表
		listTag() {
			this.$showLoading()
			listTag({
				parentId: 0
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					if (res.data && Array.isArray(res.data)) {
						if (this.tabs.length == 0) {
							this.tabs = res.data.map((item, index) => {
								if (index == 0) {
									this.tagId = item.tagId
									this.tagkey = item.tagkey
									return {
										...item,
										active: true
									}
								}
								return {
									...item,
									active: false
								}
							})
						} else {
							let rArray = res.data
							this.tabs = this.tabs.concat(rArray[rArray.length - 1])
						}
						this.onResize()
					} else {
						res.data = []
					}
				}
				
			}).catch(err => {
				console.log(err)
			}).finally(() => {
				this.$hideLoading()
			})
		},

		selectTab(tab) {
			this.tagId = tab.tagId
			this.tagkey = tab.tagId
			this.defalultSelectId = ''
			this.selectTagPath = []
			this.searchFlag = ''
			this.tabs.forEach(item => {
				item.active = false
			})
			tab.active = !tab.active
			this.transferTabs()
		},
		// 更新某一个tab名称
		updateTabs(data) {
			console.log('updateTabs', data)
			this.tabs.forEach(item => {
				if (item.active) {
					item.tagName = data.tagName
				}
			})
		},
		// 删除一个tab标签
		removeTab(data) {
			let removeIndex
			this.tabs = this.tabs.filter((item, index) => {
				if (item.tagId != data.tagId) {
					return true
				} else {
					removeIndex = index
					return false
				}
			})
			let curTab
			if (removeIndex == this.tabs.length) {
				curTab = this.tabs[removeIndex-1]
			} else {
				curTab = this.tabs[removeIndex]
			}
			console.log('removeTab', removeIndex, curTab)
			this.selectTab(curTab)
		},
		// 打开标签库弹框
		openTagLibraryCreateModal() {
			console.log('openTagLibraryCreateModal')
			this.tagLibraryCreateVisible = true
			
		},
		addTagLibrary() {
			this.$refs['addTagLibraryForm'].validate(valid => {
				if(valid) {
					console.log('validate pass')
					console.log(this.addTagLibraryForm)
					this.addTagLibraryLoading = true
					saveTag({
						parentId: '0',
						tagName: this.addTagLibraryForm.name.trim(),
						tagCode: this.addTagLibraryForm.code.trim(),
						tagSubmitAdminId: this.adminId
					}).then(res => {
						if (res.code == 200) {
							console.log('addTagLibrary', res)
							this.$message.success('添加标签库成功')
							this.tagLibraryCreateVisible = false
							this.$refs['addTagLibraryForm'].resetFields()
							this.listTag()
						} else {
							this.$message.error(res.msg || '网络异常')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.addTagLibraryLoading = false
					})
				}
			})
		},

		// 搜索
    onSearch() {
      console.log('onSearch')
			this.searchLoading = true
			this.resultBoxShow = true
			searchTag({
				tagType: 1,
				content: this.searchVal
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					this.searchLoading = false
					this.searchList = res.data.map(item => {
						let path = item.tagPath.map(v => v.v2).join('/')
						return {
							...item,
							path
						}
					})
				}
			}).catch(err => {
				this.searchLoading = false
				console.log(err)
			})
    },
		onFocusSearch() {
			if (this.searchList.length>0) {
				this.resultBoxShow = true
			}
		},
		clickOutSide() {
			// console.log('clickOutSide')
			if (this.resultBoxShow) {
				this.resultBoxShow = false
			}
		},
		// 跳转到结果标签
		toTagPath (item) {
			console.log(item)
			this.resultBoxShow = false
			if (item.parentId == 0) {
				this.tagId = item.tagId
				this.tagkey = item.tagId
				this.defalultSelectId = item.tagId
				this.selectTagPath = []
				this.searchFlag = '' + Date.now() + Math.random()
				this.tabs.forEach(ele => {
					if (this.tagId == ele.tagId) {
						ele.active = true
					} else {
						ele.active = false
					}
				})
			} else {
				let pId = item.tagPath[0].v1
				console.log('pId', pId)
				this.tagId = pId
				this.tagkey = pId
				this.defalultSelectId = item.tagId
				this.selectTagPath = item.tagPath
				this.searchFlag = '' + Date.now() + Math.random()
				this.tabs.forEach(ele => {
					if (ele.tagId == pId) {
						ele.active = true
					} else {
						ele.active = false
					}
				})
			}
			this.transferTabs()
		}
	},
}
</script>

<style lang="less">
.tag-data {
	height: 100%;
	.no-data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100%;
		background: #fff;
	}
}
.tag-main {
	height: 100%;
	position: relative;
	.md-tabs-wrap {
		position: relative;
		.tabs-inner {
			position: relative;
		}
		.tabs-inner-box {
			width: 100%;
			display: flex;
			padding-right: 340px;
			box-sizing: border-box;
			align-items: center;
		}
		.tabs-list {
			display: flex;
		}
		.tabs-item {
			width: 100px;
			flex-shrink: 0;
			padding: 0 8px;
			box-sizing: border-box;
			text-align: center;
		}
		.dropdown-wrap {
			position: relative;
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
		}
		.dropdown {
			position: absolute;
			top: 40px;
			left: 0;
			background: #fff;
			z-index: 8;
			width: 200px;
    	max-height: 210px;
			padding: 4px 4px;
    	box-shadow: 0 0 20px #e6e6e6;
			overflow-x: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 0;
			}
			.dropdown-tabs-item {
				padding: 0 16px;
				height: 32px;
				line-height: 32px;
				cursor: pointer;
				&:last-child {
					border-bottom: none;
				}
				&:hover, &.act {
					background: #eee;
				}
				.dropdown-tabs-title {
					display: block;
				}
			}
		}
	}
	.ic-add-box {
		flex-shrink: 0;
		margin-left: 2px;
		width: 28px;
		line-height: 32px;
		color: #666;
		text-align: center;
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
		&:hover {
			background: #ddd;
			color: #000;
		}
	}
	.ic-more-box {
		margin-left: 2px;
		width: 28px;
		line-height: 32px;
		color: #666;
		text-align: center;
		font-size: 11px;
		cursor: pointer;
		border-radius: 4px;
		&.active {
			background: #ddd;
			color: #000;
		}
	}
	.tag-content-wrap {
		height: calc(~"100% - 40px");
	}
	.tag-page-content {
		background: #fff;
		border: 1px solid #DCDEE3;
		border-top: none;
		display: flex;
		height: calc(~"100% - 50px");
		.left-bar {
			padding: 24px 32px 24px 24px;
			min-height: 100%;
			min-width: 250px;
			border-right: 1px solid #DCDEE3;
			box-sizing: border-box;
		}
		.right-content {
			padding: 24px 40px;
			.module {
				min-width: 400px;
				.title {
					font-size: 16px;
					margin-bottom: 16px;
				}
				.item-content {
					padding-left: 16px;
				}
				.detail {
					.tips {
						color: #999;
						padding: 8px 0;
					}
				}
			}
		}
	}
}

.tag-tree.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,
.tag-tree.ant-tree li .ant-tree-node-content-wrapper:hover {
	background-color: inherit;
	color: #0267ED;
}
</style>