var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-content-wrap"},[_c('div',{staticClass:"tab-handles-area"},[_c('div',{staticClass:"btns"},[(!_vm.selectedTag.edit)?[_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.openTagCreateModal}},[_c('i',{staticClass:"e-icon add"}),_vm._v("新增")]),(_vm.selectedTag.currentKey)?_c('a-button',{staticClass:"ml-16",attrs:{"size":"small"},on:{"click":_vm.editTag}},[_c('i',{staticClass:"e-icon edit"}),_vm._v("编辑")]):_vm._e(),(_vm.selectedTag.currentKey)?_c('a-button',{staticClass:"ml-16",attrs:{"size":"small"},on:{"click":_vm.removeTag}},[_c('i',{staticClass:"e-icon delete"}),_vm._v("删除")]):_vm._e(),_c('a-button',{staticClass:"ml-16",attrs:{"size":"small"},on:{"click":_vm.reload}},[_c('i',{staticClass:"e-icon refresh"}),_vm._v("刷新")])]:_vm._e(),(_vm.selectedTag.currentKey && _vm.selectedTag.edit)?[_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.submitUpdateTag}},[_c('i',{staticClass:"e-icon save"}),_vm._v("保存")]),_c('a-button',{staticClass:"ml-16",attrs:{"size":"small"},on:{"click":_vm.cancelUpdateTag}},[_c('i',{staticClass:"e-icon close"}),_vm._v("取消")])]:_vm._e()],2),_c('div',{staticClass:"right"})]),_c('div',{staticClass:"tag-page-content"},[_c('div',{staticClass:"left-bar"},[_c('a-tree',{staticClass:"tag-tree",attrs:{"replaceFields":{
            children:'children', title:'tagName', key:'tagId'
          },"tree-data":_vm.treeData,"defaultExpandedKeys":[_vm.selectedTag.libraryKey],"selectedKeys":[_vm.selectedTag.currentKey],"autoExpandParent":false,"expandedKeys":_vm.expandedKeys},on:{"select":_vm.onSelect,"expand":_vm.onExpand}},[_c('a-icon',{attrs:{"slot":"switcherIcon","type":"down"},slot:"switcherIcon"})],1)],1),_c('div',{staticClass:"right-content"},[_c('a-spin',{attrs:{"spinning":_vm.detailSpinning}},[(_vm.selectedTag.currentKey !== '')?[_c('div',{staticClass:"module"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.tagDetailData.parentId > 0 ? '标签详情': '标签库详情')+" ")]),_c('div',{staticClass:"detail"},[_c('a-form-model',{ref:"editTagForm",attrs:{"layout":"inline","model":_vm.editTagForm,"rules":_vm.editTagRules}},[(_vm.tagDetailData.parentId > 0)?_c('a-row',[_c('a-form-model-item',{attrs:{"label":"标签路径","prop":"tagPath"}},[(!_vm.selectedTag.edit)?_c('span',[_vm._v(_vm._s(_vm.tagDetailData.tagPathStr))]):_c('a-cascader',{staticStyle:{"width":"200px"},attrs:{"fieldNames":{
                          label: 'tagName',
                          value: 'tagId',
                          children: 'children'
                        },"options":_vm.treeData,"change-on-select":"","placeholder":"请选择标签路径"},on:{"change":_vm.addTagSelectPath},model:{value:(_vm.editTagForm.tagPath),callback:function ($$v) {_vm.$set(_vm.editTagForm, "tagPath", $$v)},expression:"editTagForm.tagPath"}})],1)],1):_vm._e(),_c('a-row',[_c('a-form-model-item',{attrs:{"label":'标签' + (_vm.tagDetailData.parentId > 0 ? '': '库') + '名称',"prop":"tagName"}},[(!_vm.selectedTag.edit)?_c('span',[_vm._v(_vm._s(_vm.tagDetailData.tagName))]):_c('a-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入标签名称"},model:{value:(_vm.editTagForm.tagName),callback:function ($$v) {_vm.$set(_vm.editTagForm, "tagName", $$v)},expression:"editTagForm.tagName"}})],1)],1),_c('a-row',[_c('a-form-model-item',{attrs:{"label":'标签' + (_vm.tagDetailData.parentId > 0 ? '': '库') + '编码'}},[_c('span',[_vm._v(_vm._s(_vm.tagDetailData.tagCode))])])],1)],1)],1)]),_c('div',{staticClass:"module",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.tagDetailData.parentId > 0 ? '标签使用': '标签库使用')+" ")]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"tips"},[_vm._v(" 该模块正在开发中... ")])])])]:_vm._e()],2)],1)]),_c('a-modal',{attrs:{"centered":"","title":"新增标签","maskClosable":false,"destroyOnClose":true,"width":650,"bodyStyle":{padding: '16px 60px'},"ok-button-props":{ props: { loading: _vm.addTagLoading } }},on:{"ok":_vm.addTag},model:{value:(_vm.tagCreateVisible),callback:function ($$v) {_vm.tagCreateVisible=$$v},expression:"tagCreateVisible"}},[_c('a-form-model',{ref:"addTagForm",attrs:{"model":_vm.addTagForm,"rules":_vm.addTagRules}},[_c('a-form-model-item',{attrs:{"label":"标签路径","prop":"path"}},[_c('a-cascader',{attrs:{"fieldNames":{
							label: 'tagName',
							value: 'tagId',
							children: 'children'
						},"options":_vm.treeData,"change-on-select":"","placeholder":"请选择标签路径"},on:{"change":_vm.addTagSelectPath},model:{value:(_vm.addTagForm.path),callback:function ($$v) {_vm.$set(_vm.addTagForm, "path", $$v)},expression:"addTagForm.path"}})],1),_c('a-form-model-item',{attrs:{"label":"标签名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入标签名称"},model:{value:(_vm.addTagForm.name),callback:function ($$v) {_vm.$set(_vm.addTagForm, "name", $$v)},expression:"addTagForm.name"}})],1),_c('a-form-model-item',{attrs:{"label":"标签编码","prop":"code"}},[_c('a-input',{attrs:{"placeholder":"请输入标签编码"},model:{value:(_vm.addTagForm.code),callback:function ($$v) {_vm.$set(_vm.addTagForm, "code", $$v)},expression:"addTagForm.code"}}),_c('span',[_vm._v("注：标签库编码一旦生成不可修改")])],1)],1)],1),_c('a-modal',{attrs:{"centered":"","title":"删除标签","maskClosable":false,"destroyOnClose":true,"confirmLoading":_vm.removeTagLoading},on:{"ok":_vm.submitRemoveTagModal},model:{value:(_vm.removeTagModal),callback:function ($$v) {_vm.removeTagModal=$$v},expression:"removeTagModal"}},[_c('div',{staticClass:"remove-tag-modal"},[_c('h4',{staticClass:"tit"},[_vm._v("该标签下包含子标签， 是否删除子标签？")]),_c('a-radio-group',{on:{"change":_vm.onRemoveChildChange},model:{value:(_vm.removeChild),callback:function ($$v) {_vm.removeChild=$$v},expression:"removeChild"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 删除 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 保留 ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }