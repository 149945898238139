<template>
  <div class="tag-content-wrap">
    <div class="tab-handles-area">
      <div class="btns">
        <template v-if="!selectedTag.edit">
          <a-button type="primary" size="small" @click="openTagCreateModal"><i class="e-icon add"></i>新增</a-button>
          <a-button v-if="selectedTag.currentKey" size="small" class="ml-16" @click="editTag"><i class="e-icon edit"></i>编辑</a-button>
          <a-button v-if="selectedTag.currentKey" size="small" class="ml-16" @click="removeTag"><i class="e-icon delete"></i>删除</a-button>
          <a-button size="small" class="ml-16" @click="reload"><i class="e-icon refresh"></i>刷新</a-button>
        </template>
        <template v-if="selectedTag.currentKey && selectedTag.edit">
          <a-button type="primary" size="small" @click="submitUpdateTag"><i class="e-icon save"></i>保存</a-button>
          <a-button size="small" class="ml-16" @click="cancelUpdateTag"><i class="e-icon close"></i>取消</a-button>
        </template>
      </div>
      <div class="right">
        
      </div>
    </div>

    <div class="tag-page-content">
      <div class="left-bar">
        <a-tree @select="onSelect" 
          :replaceFields="{
            children:'children', title:'tagName', key:'tagId'
          }"
          :tree-data="treeData" class="tag-tree"
          :defaultExpandedKeys="[selectedTag.libraryKey]"
          :selectedKeys="[selectedTag.currentKey]"
          :autoExpandParent="false"
          :expandedKeys="expandedKeys"
          @expand="onExpand"
        >
          <a-icon slot="switcherIcon" type="down" />
        </a-tree>
      </div>
      <div class="right-content">
        <a-spin :spinning="detailSpinning">
          <template v-if="selectedTag.currentKey !== ''">
            <div class="module">
              <div class="title">
                {{ tagDetailData.parentId > 0 ? '标签详情': '标签库详情' }}
              </div>
              <div class="detail">
                <a-form-model layout="inline" :model="editTagForm" :rules="editTagRules" ref="editTagForm">
                  <a-row v-if="tagDetailData.parentId > 0">
                    <a-form-model-item label="标签路径" prop="tagPath">
                      <span v-if="!selectedTag.edit">{{tagDetailData.tagPathStr}}</span>
                      <a-cascader
                        v-else
                        :fieldNames="{
                          label: 'tagName',
                          value: 'tagId',
                          children: 'children'
                        }"
                        :options="treeData" 
                        change-on-select 
                        placeholder="请选择标签路径"
                        @change="addTagSelectPath" 
                        v-model="editTagForm.tagPath"
                        style="width:200px;"
                      />
                    </a-form-model-item>
                  </a-row>
                  <a-row>
                    <a-form-model-item :label="'标签' + (tagDetailData.parentId > 0 ? '': '库') + '名称'"  prop="tagName">
                      <span v-if="!selectedTag.edit">{{tagDetailData.tagName}}</span>
                      <a-input v-else placeholder="请输入标签名称" v-model="editTagForm.tagName" style="width: 200px" />
                    </a-form-model-item>
                  </a-row>
                  <a-row>
                    <a-form-model-item :label="'标签' + (tagDetailData.parentId > 0 ? '': '库') + '编码'">
                      <span>{{tagDetailData.tagCode}}</span>
                      <!-- <a-input v-else placeholder="请输入标签编码" style="width: 200px" /> -->
                    </a-form-model-item>
                  </a-row>
                </a-form-model>
              </div>
            </div>
            <div class="module" style="margin-top: 60px">
              <div class="title">
                {{ tagDetailData.parentId > 0 ? '标签使用': '标签库使用' }}
              </div>
              <div class="detail">
                <div class="tips">
                  该模块正在开发中...
                </div>
              </div>
            </div>
          </template>
        </a-spin>
      </div>
    </div>
		<!-- 新增标签 -->
		<a-modal
      v-model="tagCreateVisible"
      centered
      title="新增标签"
      @ok="addTag"
      :maskClosable="false" 
      :destroyOnClose="true"
      :width="650"
      :bodyStyle="{padding: '16px 60px'}"
      :ok-button-props="{ props: { loading: addTagLoading } }"
    >
      <a-form-model :model="addTagForm" :rules="addTagRules" ref="addTagForm" 
        
      >
				<a-form-model-item label="标签路径" prop="path">
          <a-cascader
						:fieldNames="{
							label: 'tagName',
							value: 'tagId',
							children: 'children'
						}"
						:options="treeData"
						change-on-select
						placeholder="请选择标签路径"
            v-model="addTagForm.path"
            @change="addTagSelectPath"
					/>
        </a-form-model-item>
        <a-form-model-item label="标签名称" prop="name">
          <a-input placeholder="请输入标签名称" v-model="addTagForm.name" />
        </a-form-model-item>
        <a-form-model-item label="标签编码" prop="code">
          <a-input placeholder="请输入标签编码" v-model="addTagForm.code" />
          <span>注：标签库编码一旦生成不可修改</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>


    <!-- 删除标签 modal -->
    <a-modal
      v-model="removeTagModal"
      centered
      title="删除标签"
      @ok="submitRemoveTagModal"
      :maskClosable="false" 
      :destroyOnClose="true"
      :confirmLoading="removeTagLoading"
    >
      <div class="remove-tag-modal">
        <h4 class="tit">该标签下包含子标签， 是否删除子标签？</h4>
        <a-radio-group v-model="removeChild" @change="onRemoveChildChange">
          <a-radio :value="1">
            删除
          </a-radio>
          <a-radio :value="2">
            保留
          </a-radio>
        </a-radio-group>
      </div>
    </a-modal>

	</div>
</template>

<script>
import { listTree, tagDetail, saveTag, updateTag, removeTag } from '@/api/tag'
import { validateTagName } from '@/utils/validate'
import { mapGetters } from 'vuex'
function findPath(arr, target) {
  let pathArr = []
  function find(arr, target, deep) {
    console.log('find', arr, target)
    for(let i = 0; i < arr.length; i ++) {
      let ele = arr[i]
      console.log('deep', deep)
      pathArr = pathArr.slice(0, deep)
      pathArr.push({
        tagId: ele.tagId,
        tagName: ele.tagName
      })
      console.log('pathArr', pathArr)
      console.log('比较', ele.tagId,target)
      if (ele.tagId == target) {
        break
      }
      console.log('ceshi', arr, i)
      if (ele.children && ele.children.length > 0) {
        find(ele.children, target, deep + 1)
      }
    }
  }
  find(arr, target, 0)
  return pathArr
}

export default {
	name: 'tag-structural-content',
  props: ['id', 'defalultSelectId', 'selectTagPath', 'searchFlag'],
  computed: {
		...mapGetters(['adminId']),
	},
	data() {
    const validateName =  (rule, value, callback) => {
			// console.log(rule, value, callback)
			value = value.trim()
			if (value === '') {
					callback(new Error('请输入标签名称'))
			} else if (!validateTagName(value)) {
					callback(new Error('名称由英文字母、数字、中文字符、下划线、中划线、点组成'))
			} else {
					callback();
			}
		}
		const validateCode =  (rule, value, callback) => {
			// console.log(rule, value, callback)
			value = value.trim()
			if (value === '') {
					callback(new Error('请输入标签编码'))
			} else if (!validateTagName(value)) {
					callback(new Error('编码由英文字母、数字、中文字符、下划线、中划线、点组成'))
			} else {
					callback();
			}
		}
    const validatePath = (rule, value, callback) => {
      if (value.length <=0) {
        callback(new Error('请选择标签路径'))
      } else {
        callback()
      }
    }
		return {
			// 新增标签弹框
			tagCreateVisible: false,
			addTagLoading: false,
			addTagForm: {
        path: [],
				name: '',
				code: ''
			},
			addTagRules: {
        path: [{ required: true, message: '请选择标签路径', trigger: 'change'}],
				name: [{ required: true, validator: validateName, trigger: 'blur'}],
				code: [{ required: true, validator: validateCode, trigger: 'blur'}]
			},

      // 编辑标签
      editTagForm: {
        tagPath: [],
        tagName: ''
      },
      editTagRules: {
        tagPath: [{validator: validatePath, trigger: 'change'}],
				tagName: [{validator: validateName, trigger: 'blur'}],
			},

			// 标签库数据
			treeData: [],

      // 标签详情
      tagDetailData: {
        tagName: '',
        tagCode: '',
        tagPath: [],
        tagPathStr: ''
      },
      detailSpinning: false,

			selectedTag: {
				libraryKey: '',
				currentKey: '',
				edit: false,
        isLeaf: false // 是否是叶子标签
			},
      expandedKeys: [],

      // 删除标签（非叶子标签）
      removeTagModal: false,
      removeTagLoading: false,
      removeChild: 1,

      watchFirst: false,
		}
	},
  created() {
    console.log('created', this.id, this.selectedTag.libraryKey,'this.selectedTag.libraryKey')
    if (!this.watchFirst) {
      this.selectedTag.libraryKey = this.id
      this.selectedTag.currentKey = this.id
      this.expandedKeys = [+this.id]
      this.listTree()
      this.tagDetail()
    }
  },
  activated() {
    console.log('tag-structural-content:activated', this.id, this.defalultSelectId, this.selectTagPath)
  },
  watch: {
    searchFlag: {
      handler:function(value) {
        console.log('watch', value)
        if (value) {
          this.watchFirst = true
          if (!this.selectedTag.libraryKey) {
            this.selectedTag.libraryKey = this.id
            this.listTree(() => {
              this.autoExpandTag()
            })
          } else {
            this.autoExpandTag()
          }
        }
      },
      immediate: true,
    },
  },
	methods: {
    // 根据路径自动展开对应标签
    autoExpandTag() {
      this.selectedTag.currentKey = this.defalultSelectId
      let tagPathArray = []
      if (Array.isArray(this.selectTagPath)) {
        tagPathArray = this.selectTagPath.map(t => t.v1)
      }
      this.expandedKeys = [...tagPathArray]
      this.tagDetail()
    },
    // 展开
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      // this.autoExpandParent = false
    },
    // 获取标签库
    listTree(cb) {
      if (!this.id) return 
      this.$showLoading()
      listTree({tagId: this.id}).then(res => {
        console.log('listTree', res)
        if (res.code == 200) {
          this.treeData = res.data
          if (cb) cb()
        }
      }).catch(err => {
        console.log('listTree', err)
      }).finally(() => {
        this.$hideLoading()
      })
    },
    // 获取标签详情
    tagDetail() {
      if (!this.selectedTag.currentKey) return
      tagDetail(this.selectedTag.currentKey).then(res => {
        if (res.code == 200) {
          console.log(res)
          this.tagDetailData = res.data
          this.tagDetailData.tagPathStr = res.data.tagPath.map(item => item.v2).join('/')
          this.editTagForm.tagPath = res.data.tagPath.map(item => item.v1)
          this.editTagForm.tagName = res.data.tagName
        } else {
          this.$message.error(res.msg || '网络错误，获取标签详情失败')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.detailSpinning = false
      })
    },
    // 获取路径
    getTagPath() {
      let result = findPath(this.treeData, this.selectedTag.currentKey)
      console.log(result)
    },

    // 获取路径 
    // getPath() {
    //   getPath({
    //     tagId: this.selectedTag.libraryKey,
    //     separator: '\\'
    //   }).then(res => {
    //     console.log(res)
    //   }).catch(err => {
    //     console.warn(err)
    //   })
    // },

		// 打开添加标签弹框
		openTagCreateModal() {
			console.log('openTagCreateModal')
			this.tagCreateVisible = true
		},

    // 添加标签
		addTag() {
			this.$refs['addTagForm'].validate(valid => {
        console.log('addTag1', this.addTagForm)
        if (valid) {
          console.log('addTag2', this.addTagForm)
          this.addTagLoading = true
          let parentId = this.addTagForm.path[this.addTagForm.path.length - 1]
          saveTag({
						parentId,
						tagName: this.addTagForm.name.trim(),
						tagCode: this.addTagForm.code.trim(),
						tagSubmitAdminId: this.adminId
					}).then(res => {
						if (res.code == 200) {
							console.log('addTagLibrary', res)
							this.$message.success('添加标签成功')
							this.tagCreateVisible = false
							this.listTree()
              this.$refs['addTagForm'].resetFields()
						} else {
							this.$message.error(res.msg || '网络异常')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.addTagLoading = false
					})
        }
      })
		},

    // 修改标签
    editTag() {
      console.log('修改标签')
      this.selectedTag.edit = true
    },
    // 提交修改标签
    submitUpdateTag() {
      console.log(this.editTagForm)
      this.$refs['editTagForm'].validate(valid => {
        if (valid) {
          console.log('pass')
          this.$showLoading()
          let parentId
          if (this.tagDetailData.parentId == 0) {
            parentId = 0
          } else {
            parentId = this.editTagForm.tagPath[this.editTagForm.tagPath.length - 1]
          }
          let tagName = this.editTagForm.tagName.trim()
          updateTag({
            tagId: this.tagDetailData.tagId,
            parentId,
            tagName,
            tagLastUpdateAdminId: this.adminId
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.selectedTag.edit = false
              this.listTree()
              this.tagDetail()
              if (parentId == 0) {
                console.log('emit: update-tabs')
                this.$emit('update-tabs', {tagName})
              }
            } else {
              this.$message.error(res.msg || '网络异常')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        }
      })
      
    },
    // 取消修改标签
    cancelUpdateTag() {
      this.selectedTag.edit = false
    },
    // 删除标签 
    removeTag() {
      if (this.tagDetailData.parentId == 0) {
        // 删除标签库
        this.$confirm({
          title: `删除标签库`,
          content: '当前标签为根目录，子标签将一同删除且无法恢复，是否确定删除？',
          // content: h => <div>Some descriptions</div>,
          centered: true,
          onOk: () => {
            console.log("OK")
            this.$showLoading()
            removeTag({
             tagIds: this.tagDetailData.tagId,
             isDelSubset: true,
             tagLastUpdateAdminId: this.adminId
            }).then(res => {
              console.log(res)
              if (res.code === 200) {
                this.$message.success(`删除成功`)
                this.$emit('remove-tab', {
                  tagId: this.tagDetailData.tagId
                })
              } else {
                this.$message.error(res.msg || '操作失败')
              }
            }).catch(err => {
              console.warn(err)
            }).finally(() => {
              this.$hideLoading()
            })
          },
          onCancel: () => {
            console.log("Cancel");
          },
        });
      } else {
        // 删除普通标签
        if (this.selectedTag.isLeaf) { // 删除叶子标签
          this.$confirm({
            title: '确认删除该标签吗?',
            centered: true,
            onOk: () => {
              console.log("OK")
              this.$showLoading()
              removeTag({
                tagIds: this.selectedTag.currentKey,
                isDelSubset: true,
                tagLastUpdateAdminId: this.adminId
              }).then(res => {
                console.log(res)
                if (res.code === 200) {
                  this.$message.success(`删除成功`)
                  this.selectedTag.currentKey = ''
                  this.listTree()
                } else {
                  this.$message.error(res.msg || '操作失败')
                }
              }).catch(err => {
                console.warn(err)
              }).finally(() => {
                this.$hideLoading()
              })
            },
            onCancel: () => {
              console.log("Cancel");
            },
          })
        } else { // 删除标签（非叶子标签）
          this.removeTagModal = true
        }
        
      }
    },

    // 提交删除标签（非叶子标签） 
    submitRemoveTagModal() {
      console.log(this.removeChild)
      console.log(this.selectedTag.currentKey)
      // this.removeTagLoading = true
      this.$showLoading()
      let isDelSubset = this.removeChild == 1 ? true : false
      removeTag({
        tagIds: this.selectedTag.currentKey,
        isDelSubset,
        tagLastUpdateAdminId: this.adminId
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success(`删除成功`)
          this.selectedTag.currentKey = ''
          this.listTree()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        // this.removeTagLoading = false
        this.removeTagModal = false
        this.removeChild = 1
        this.$hideLoading()
      })
    },
    // 选择是否删除子标签
    onRemoveChildChange(value) {
      console.log(value)
    },

    // 选择标签路径
		addTagSelectPath(value) {
			console.log(value)
      // this.addTagForm.path = value
		},

		onSelect(selectedKeys, info) {
      if (selectedKeys.length == 0) {
        this.selectedTag.currentKey = ''
        return false
      }
      console.log('selected', selectedKeys, info);
      this.selectedTag.currentKey = selectedKeys[0]
      // console.log('info.node.$options.propsData.dataRef.children', info.node.$options.propsData.dataRef.children.length == 0)
      this.selectedTag.isLeaf = info.node?.$options?.propsData?.dataRef?.children?.length == 0
      this.detailSpinning = true
      this.tagDetail()
      // this.getTagPath()
    },
    onCheck(checkedKeys, info) {
      console.log('onCheck', checkedKeys, info);
    },
    reload() {
      this.listTree()
      this.tagDetail()
    }
	}
}
</script>
<style lang="less" scoped>
.remove-tag-modal {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 16px;
  .tit {
    margin-bottom: 16px;
  }
}
</style>

